import React, { useRef, useLayoutEffect, useState, LegacyRef } from 'react'
import Menu from './Menu'
import rough from 'roughjs'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../store/store'
import { actions, cursorPositions, toolTypes } from '../constants'
import {
  adjustElementCoordinates,
  adjustmentRequired,
  createElement,
  drawElement,
  getCursorForPosition,
  getElementAtPosition,
  getResizedCoordinates,
  updateExistingElement,
} from './utils'
import { v4 as uuid } from 'uuid'
import { updateElement } from './whiteboardSlice'
import { updatePencilElementWhenMoving } from './utils/updateExistingElement'
import { emitCursorPosition } from '../socketConn/socketConn'

let emitCursor = true
let lastCursorPosition:any

function Whiteboard() {
  const canvasRef = useRef<HTMLCanvasElement>(null)
  const textareaRef = useRef<HTMLTextAreaElement>(null)
  const toolType = useSelector((state: RootState) => state.whiteboard.tool)
  const elements = useSelector((state: RootState) => state.whiteboard.elements)
  const [action, setAction] = useState<any>(null)
  const [selectedElement, setSelectedElement] = useState<any>(null)

  const dispatch = useDispatch()

  useLayoutEffect(() => {
    const canvas = canvasRef.current

    if (canvas) {
      const ctx = canvas.getContext('2d')
      ctx?.clearRect(0, 0, canvas?.width, canvas?.height)
      const roughCanvas = rough.canvas(canvas)

      elements.forEach((element: any) => {
        drawElement({ roughCanvas, context: ctx, element })
      })
    }
  }, [elements])

  const handleMouseDown = (event: any) => {
    const { clientX, clientY } = event

    if (selectedElement && action === actions.WRITING) {
      return
    }

    switch (toolType) {
      case toolTypes.RECTANGLE:
      case toolTypes.LINE:
      case toolTypes.PENCIL: {
        const element = createElement({
          x1: clientX,
          y1: clientY,
          x2: clientX,
          y2: clientY,
          toolType,
          id: uuid(),
        })
        setAction(actions.DRAWING)
        setSelectedElement(element)
        dispatch(updateElement(element))
        break
      }
      case toolTypes.TEXT: {
        const element = createElement({
          x1: clientX,
          y1: clientY,
          x2: clientX,
          y2: clientY,
          toolType,
          id: uuid(),
        })
        setAction(actions.WRITING)
        setSelectedElement(element)
        dispatch(updateElement(element))
        break
      }

      case toolTypes.SELECTION: {
        const element: any = getElementAtPosition({
          clientX,
          clientY,
          elements,
        })

        if (
          element &&
          (element.type === toolTypes.RECTANGLE ||
            element.type === toolTypes.TEXT ||
            element.type === toolTypes.LINE)
        ) {
          setAction(
            element.position === cursorPositions.INSIDE
              ? actions.MOVING
              : actions.RESIZING
          )

          const offsetX = clientX - element.x1
          const offsetY = clientY - element.y1

          setSelectedElement({ ...element, offsetX, offsetY })
        }

        if (element && element.type === toolTypes.PENCIL) {
          setAction(actions.MOVING)

          const xOffsets = element.points.map((point: any) => clientX - point.x)
          const yOffsets = element.points.map((point: any) => clientY - point.y)

          setSelectedElement({ ...element, xOffsets, yOffsets })
        }

        break
      }
    }
  }

  const handleMouseUp = () => {
    const selectedElementIndex = elements.findIndex((el: any) => {
      return el.id === selectedElement?.id
    })

    if (selectedElementIndex !== -1) {
      if (action === actions.DRAWING || action === actions.RESIZING) {
        if (adjustmentRequired(elements[selectedElementIndex].type)) {
          console.log('Adjustment required')
          const { x1, y1, x2, y2 } = adjustElementCoordinates(
            elements[selectedElementIndex]
          )
          updateExistingElement(
            {
              index: selectedElementIndex,
              id: selectedElement.id,
              x1,
              y1,
              x2,
              y2,
              type: elements[selectedElementIndex].type,
            },
            elements
          )
        }
      }
    }
    setAction(null)
    setSelectedElement(null)
  }

  const handleMouseMove = (event: any) => {
    const { clientX, clientY } = event

    lastCursorPosition={ x: clientX, y: clientY }

    if (emitCursor) {
      emitCursorPosition({ x: clientX, y: clientY })
      emitCursor = false

      setTimeout(() => {
        emitCursor = true
        emitCursorPosition(lastCursorPosition)
      }, 50)
    }

    if (action === actions.DRAWING) {
      const index = elements.findIndex(
        (el: any) => el.id === selectedElement.id
      )
      if (index !== -1) {
        updateExistingElement(
          {
            index,
            id: elements[index].id,
            x1: elements[index].x1,
            y1: elements[index].y1,
            x2: clientX,
            y2: clientY,
            type: elements[index].type,
          },
          elements
        )
      }
    }

    if (toolType === toolTypes.SELECTION) {
      const element = getElementAtPosition({ clientX, clientY, elements })

      event.target.style.cursor = element
        ? getCursorForPosition(element.position)
        : 'default'
    }

    if (
      toolType === toolTypes.SELECTION &&
      action === actions.MOVING &&
      selectedElement.type === toolTypes.PENCIL
    ) {
      const newPoints = selectedElement.points.map((_: any, index: any) => ({
        x: clientX - selectedElement.xOffsets[index],
        y: clientY - selectedElement.yOffsets[index],
      }))

      const index = elements.findIndex(
        (element: any) => element.id === selectedElement.id
      )
      if (index != -1) {
        updatePencilElementWhenMoving({ index, newPoints }, elements)
      }

      return
    }

    if (
      toolType === toolTypes.SELECTION &&
      action === actions.MOVING &&
      selectedElement
    ) {
      const { id, x1, x2, y1, y2, type, offsetX, offsetY, text } =
        selectedElement

      const width = x2 - x1
      const height = y2 - y1

      const newX1 = clientX - offsetX
      const newY1 = clientY - offsetY

      const index = elements.findIndex(
        (element: any) => element.id === selectedElement.id
      )

      if (index !== -1) {
        updateExistingElement(
          {
            id,
            x1: newX1,
            y1: newY1,
            x2: newX1 + width,
            y2: newY1 + height,
            type,
            index,
            text,
          },
          elements
        )
      }
    }

    if (
      toolType === toolTypes.SELECTION &&
      action === actions.RESIZING &&
      selectedElement
    ) {
      const { id, type, position, ...coordinates } = selectedElement
      const { x1, x2, y1, y2 } = getResizedCoordinates(
        clientX,
        clientY,
        position,
        coordinates
      )

      const selectedElementIndex = elements.findIndex(
        (element: any) => element.id === selectedElement.id
      )

      if (selectedElementIndex !== -1) {
        updateExistingElement(
          {
            x1,
            y1,
            x2,
            y2,
            id: selectedElement.id,
            type: selectedElement.type,
            index: selectedElementIndex,
          },
          elements
        )
      }
    }
  }

  const handleTextareaBlur = (event: any) => {
    const { id, x1, y1, type } = selectedElement
    const index = elements.findIndex(
      (element: any) => element.id === selectedElement.id
    )

    if (index !== -1) {
      updateExistingElement(
        { id, x1, y1, type, text: event.target.value, index },
        elements
      )
      setAction(null)
      setSelectedElement(null)
    }
  }

  return (
    <>
      <Menu />
      {action === actions.WRITING ? (
        <textarea
          ref={textareaRef}
          onBlur={handleTextareaBlur}
          style={{
            position: 'absolute',
            top: selectedElement.y1 - 3,
            left: selectedElement.x1,
            font: '24px sans-serif',
            margin: 0,
            padding: 0,
            border: 0,
            outline: 0,
            resize: 'both',
            overflow: 'hidden',
            whiteSpace: 'pre',
            background: 'transparent',
          }}
        />
      ) : null}
      <canvas
        id={'canvas'}
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
        onMouseMove={handleMouseMove}
        ref={canvasRef}
        width={window.innerWidth}
        height={window.innerHeight}
      />
    </>
  )
}

export default Whiteboard
