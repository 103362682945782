import { createSlice } from "@reduxjs/toolkit"

const initialState: any = {
  tool: null,
  elements: []
}

const whiteboardSlice = createSlice(
  {
    name: "whiteboard",
    initialState,
    reducers: {
      setToolType: (state, action) => {
        state.tool = action.payload
      },
      updateElement: (state, action) => {
        const { id } = action.payload

        const index = state.elements.findIndex((element: any) => element.id === id)

        if (index === -1) {
          state.elements = [...state.elements, action.payload]
        } else {
          state.elements[index] = action.payload
        }

      },
      setElements: (state, action) => {
        state.elements = action.payload
      }

    }
  }
)

export const { setToolType, updateElement, setElements } = whiteboardSlice.actions

export default whiteboardSlice.reducer