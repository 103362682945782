import React, { useEffect } from 'react'
import Whiteboard from './Whiteboard/Whiteboard'
import { connectWithSocketServer } from './socketConn/socketConn'
import CursorOverlay from './CursorOverlay/CursorOverlay'

const App: React.FC = () => {
  useEffect(() => {
    connectWithSocketServer()
  }, [])

  return (
    <>
      <Whiteboard />
      <CursorOverlay/>
    </>
  )
}

export default App
