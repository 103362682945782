import { configureStore, createSlice, getDefaultMiddleware } from '@reduxjs/toolkit'
import whiteboardSliceReducer from '../Whiteboard/whiteboardSlice'
import cursorSliceReducer from '../CursorOverlay/cursorSlice'
// import { setupListeners } from '@reduxjs/toolkit/query'


export const store = configureStore({
  reducer: {
    whiteboard: whiteboardSliceReducer,
    cursor: cursorSliceReducer
  },
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ["whiteboard/updateElement", "whiteboard/setElements"],
        ignoredPaths: ["whiteboard.elements"]
      }
    })
  }
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

// setupListeners(store.dispatch)
