import React from 'react'
import { useSelector } from 'react-redux'
import cursorIcon from '../resources/icons/selection.svg'
import { RootState } from '../store/store'

function CursorOverlay() {
  const cursors = useSelector((state: RootState) => state.cursor.cursors)
  return (
    <>
      {cursors.map((cursor: any) => (
        <img
          key={cursor.userId}
          className="cursor"
          src={cursorIcon}
          style={{
            position: 'absolute',
            left: cursor.x,
            top: cursor.y,
            width: '30px',
          }}
        />
      ))}
    </>
  )
}

export default CursorOverlay
