import { cursorPositions, toolTypes } from "../../constants"

const distance = (a: any, b: any) => Math.sqrt(Math.pow(a.x - b.x, 2) + Math.pow(a.y - b.y, 2))


const onLine = ({ x1, y1, x2, y2, x, y, maxDistance = 1 }: any) => {
    const a = { x: x1, y: y1 }
    const b = { x: x2, y: y2 }
    const c = { x, y }

    const offset = distance(a, b) - (distance(a, c) + distance(b, c))

    return Math.abs(offset) < maxDistance ? cursorPositions.INSIDE : null
}

const nearPoint = (x: any, y: any, x1: any, y1: any, cursorPosition: any) => {
    return Math.abs(x - x1) < 5 && Math.abs(y - y1) < 5 ? cursorPosition : null
}

const positionWithinElement = (x: any, y: any, element: any) => {
    const { type, x1, x2, y1, y2 } = element

    switch (type) {
        case toolTypes.RECTANGLE:
            const topLeft = nearPoint(x, y, x1, y1, cursorPositions.TOP_LEFT)
            const topRight = nearPoint(x, y, x2, y1, cursorPositions.TOP_RIGHT)
            const bottomLeft = nearPoint(x, y, x1, y2, cursorPositions.BOTTOM_LEFT)
            const bottomRight = nearPoint(x, y, x2, y2, cursorPositions.BOTTOM_RIGHT)
            const inside =
                x >= x1 && x <= x2 && y >= y1 && y <= y2 ?
                    cursorPositions.INSIDE : null;
            return topLeft || topRight || bottomLeft || bottomRight || inside

        case toolTypes.TEXT:
            return x >= x1 && x <= x2 && y >= y1 && y <= y2 ?
                cursorPositions.INSIDE : null;

        case toolTypes.LINE:
            const on = onLine({ x1, y1, x2, y2, x, y, })
            const start = nearPoint(x, y, x1, y1, cursorPositions.START)
            const end = nearPoint(x, y, x2, y2, cursorPositions.END)
            return start || end || on;
        case toolTypes.PENCIL:
            const betweenAnyPoint = element.points.some((point: any, index: number) => {
                const nextPoint = element.points[index + 1]
                if (!nextPoint) return false
                return (
                    onLine({
                        x1: point.x, y1: point.y,
                        x2: nextPoint.x, y2: nextPoint.y,
                        x, y, maxDistance: 5
                    })
                )

            })

            return betweenAnyPoint ? cursorPositions.INSIDE : null
    }

}


export const getElementAtPosition = ({ clientX, clientY, elements }: any) => {
 
    return elements.map((element: any) => ({
        ...element,
        position: positionWithinElement(clientX, clientY, element)
    })).find((element: any) => element.position !== null && element.position !== undefined)
}