import { io, Socket } from "socket.io-client";
import { store } from "../store/store";
import { setElements, updateElement } from "../Whiteboard/whiteboardSlice";
import { removeCursorPosition, updateCursorPosition } from "../CursorOverlay/cursorSlice";

let socket: Socket;

export const connectWithSocketServer = () => {
    socket = io('http://localhost:3001');

    socket.on('connect', () => {
        console.log("Connected to socket server");
    })

    socket.on('whiteboard-state', (elements) => {
        store.dispatch(setElements(elements))
    })

    socket.on('element-update', (elementData) => {
        store.dispatch(updateElement(elementData))
    })

    socket.on('whiteboard-clear', () => {
        store.dispatch(setElements([]))
    })

    socket.on('cursor-position', (cursorData) => {
        store.dispatch(updateCursorPosition(cursorData))
    })

    socket.on('user-disconnected', (disconnectedId) => {
        store.dispatch(removeCursorPosition(disconnectedId))
    })
}


export const emitElementUpdate = (elementData: any) => {
    socket.emit("element-update", elementData)
}

export const emitClearWhiteboard = () => {
    socket.emit('whiteboard-clear')
}

export const emitCursorPosition = (position: any) => {
    socket.emit('cursor-position', position)
}